<template>
  <!-- 保险>员工校方责任险园校 -->
  <div class="contain">
    <div class="form-header-btn">
      <div class="header-title">员工校方责任险</div>
      <div v-if="tabClickIndex && tabClickIndex == 1">
        <el-button
          type="primary"
          class="sheet-btn"
          @click="downLoadFileNo"
          v-if="isUninsuredExport"
        >
          导出
        </el-button>
        <el-button
          class="sheet-btn"
          @click="onSync"
          :disabled="disabledSync"
          v-if="isUninsuredSync"
        >
          同步
        </el-button>
      </div>
      <div v-else>
        <el-button
          type="primary"
          class="sheet-btn"
          @click="showFile"
          v-if="isView"
        >
          查看保单
        </el-button>
        <el-button
          type="primary"
          class="sheet-btn"
          @click="downLoadFile"
          v-if="isExport"
        >
          导出
        </el-button>
      </div>
    </div>
    <el-tabs type="border-card" @tab-click="handleTabClick">
      <el-tab-pane>
        <span slot="label">全部</span>
        <div>
          <!-- 表单 -->
          <el-form
            :model="form"
            onsubmit="return false"
            :inline="true"
            class="search-form"
          >
            <el-form-item>
              <el-input
                v-model.trim="form.policyholder"
                placeholder="投保人"
                clearable
                class="select-condition"
              />
            </el-form-item>

            <el-form-item>
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="searchFun"
              >
                查询
              </el-button>
            </el-form-item>

            <el-form-item>
              <el-button
                @click="resetFun"
                icon="el-icon-refresh-left"
                class="reset"
              >
                重置
              </el-button>
            </el-form-item>
          </el-form>

          <!-- 表格 -->
          <el-table
            :data="tableData"
            :header-cell-style="{
              background: '#F1F4F9',
              color: '#5E6D9B',
              fontWeight: '400',
              'text-align': 'center',
            }"
            v-loading="tableLoading"
          >
            <el-table-column
              type="index"
              label="序号"
              min-width="80"
              align="center"
            ></el-table-column>
            <!--            <el-table-column
              type="index"
              label="各园区小序号"
              width="110"
              align="center"
            ></el-table-column>-->
            <el-table-column
              prop="insuranceType"
              label="保险类型"
              min-width="110"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="policyholder"
              label="投保人"
              min-width="110"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="identityNumber"
              label="身份证号/护照号"
              min-width="200"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="gender"
              label="性别"
              min-width="100"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="birthday"
              label="生日"
              min-width="110"
              align="center"
            ></el-table-column>
          </el-table>

          <!-- 分页 -->
          <div class="manger-footer">
            <div>
              <span class="footer-left-title">园校投保率：</span>
              <span
                :class="
                  infoRate < 100 ? 'footer-left-num' : 'footer-left-title'
                "
              >
                {{ infoRate || 0 }}%
              </span>
            </div>
            <div class="footer-pagination">
              <el-pagination
                background
                @size-change="handleSizeChange"
                layout="total,sizes, prev, pager, next, jumper"
                :total="pagination.total"
                :page-size="pagination.size"
                @current-change="handleCurrentChangePage"
                :current-page.sync="pagination.currentPage"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </el-tab-pane>

      <el-tab-pane label="未投保预警">
        <div style="padding-top: 1.875vw">
          <!-- 表单未投保预警 -->

          <!-- 表格 -->
          <div>
            <el-row>
              <el-col :span="12">
                <div class="noinsure-title">变更前（已离职）</div>
                <div class="">
                  <el-table
                    :data="tableNotinsure.beforeList"
                    border
                    :header-cell-style="{
                      background: '#F1F4F9',
                      color: '#5E6D9B',
                      fontWeight: '400',
                      'text-align': 'center',
                    }"
                    v-loading="tableLoading"
                    @selection-change="handleBeforeSelectionChange"
                  >
                    <el-table-column
                      v-if="!disabledSync"
                      type="selection"
                      width="55"
                      align="center"
                    >
                    </el-table-column>
                    <el-table-column
                      type="index"
                      label="序号"
                      width="60"
                      align="center"
                    ></el-table-column>
                    <el-table-column
                      prop="policyholder"
                      label="姓名"
                      align="center"
                    ></el-table-column>
                    <el-table-column
                      prop="parkName"
                      label="所属园校"
                      align="center"
                    ></el-table-column>
                    <el-table-column
                      prop="identityNumber"
                      label="身份证号"
                      width="200"
                      align="center"
                    ></el-table-column>
                  </el-table>
                </div>
              </el-col>
              <el-col :span="12">
                <div class="noinsure-title">变更后（新入职未投保）</div>
                <div>
                  <el-table
                    :data="tableNotinsure.afterList"
                    border
                    :header-cell-style="{
                      background: '#F1F4F9',
                      color: '#5E6D9B',
                      fontWeight: '400',
                      'text-align': 'center',
                    }"
                    v-loading="tableLoading"
                    @selection-change="handleAfterSelectionChange"
                  >
                    <el-table-column
                      v-if="!disabledSync"
                      type="selection"
                      width="55"
                      align="center"
                    >
                    </el-table-column>
                    <el-table-column
                      type="index"
                      label="序号"
                      width="60"
                      align="center"
                    ></el-table-column>
                    <el-table-column
                      prop="policyholder"
                      label="姓名"
                      align="center"
                    ></el-table-column>
                    <el-table-column
                      prop="parkName"
                      label="所属园校"
                      align="center"
                    ></el-table-column>
                    <el-table-column
                      prop="identityNumber"
                      label="身份证号"
                      width="200"
                      align="center"
                    ></el-table-column>
                  </el-table>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
    <onlinePreview
      v-if="attachmentFilelist.length > 0"
      :onlinePreviewVisible="onlinePreviewVisible"
      :type="fileAttachment.previewType"
      @closeOnlinePreviewDialog="closeOnlinePreviewDialog"
      :url="fileAttachment.url"
      :title="attachmentFilelist[0].attachmentName || ''"
    />
  </div>
</template>

<script>
import { getPermissionButton } from "@/utils/getUrlPermissionButtons";
import { mapGetters, mapState } from "vuex";
import downloadFile from "@/utils/downloadFile";
import onlinePreview from "@/components/onlinePreview/index";
export default {
  data() {
    return {
      form: {
        parkId: "",
        classId: "",
        policyholder: "",
      },
      formDan: {
        fileList: [],
      },
      fileList: [],
      tableData: [],
      tableNotinsure: [], //未投保
      beforeSelectList: [], //未投保-已离职选中list
      afterSelectList: [], //未投保-新入职选中list
      pagination: {
        total: 0, // 总页数
        currentPage: 1, // 当前页数
        size: 10, // 每页显示多少条
      },
      tableLoading: false, // 表格loading
      exportStatus: false,
      disabledSync: true,
      infoRate: "", //园校投保率
      tabClickIndex: "",
      fileAttachment: {},
      onlinePreviewVisible: false,
      attachmentFilelist: [],
      permissionButtonList: [], // 权限按钮list
      isView: false,
      isExport: false,
      isUninsuredExport: false,
      isUninsuredSync: false,
      branchId: "",
      schoolId: "",
    };
  },

  components: {
    onlinePreview,
  },

  computed: {
    ...mapState({
      menuList: (state) => state.common.menuList,
      userInfo: (state) => state.userInfo,
    }),
  },

  watch: {
    permissionButtonList: {
      handler(val) {
        if (val) {
          this.isView = val.indexOf("y_employeeInsurance:view") != -1;
          this.isExport = val.indexOf("y_employeeInsurance:export") != -1;
          this.isUninsuredExport =
            val.indexOf("y_employeeInsurance:uninsuredExport") != -1;
          this.isUninsuredSync =
            val.indexOf("y_employeeInsurance:uninsuredSync") != -1;
        }
      },
      deep: true,
    },
    menuList: {
      handler(val) {
        if (val) {
          this.getPermissionButton(this.$route.path, (data) => {
            this.permissionButtonList = data;
          });
        }
      },
      deep: true,
    },
  },

  created() {
    // menulist一直在时这个方法不可缺少
    this.getPermissionButton(this.$route.path, (data) => {
      this.permissionButtonList = data;
    });
  },

  mounted() {
    if (this.userInfo.schoolList.length > 0) {
      this.branchId = this.userInfo.schoolList[0].companyLabel;
    }

    if (this.userInfo.schoolList.length > 0) {
      this.schoolId = this.userInfo.schoolList[0].schoolLabel;
    }

    // this.tableLoading = true;
    // this.init();
    this.getInfoRate();
    this.getNotinsure();
    this.getAttachmentPage();
  },
  activated() {
    this.tableLoading = true;
    this.init();
  },

  methods: {
    getPermissionButton,
    /** 初始化 */
    init() {
      let params = {
        schoolId: this.schoolId,
        type: "2",
        parkId: "",
        policyholder: this.form.policyholder,
        current: this.pagination.currentPage,
        size: this.pagination.size,
      };

      this.$api.getInsuranceInfo(params).then((res) => {
        this.tableLoading = false;
        if (res.data.code == 0) {
          this.tableData = res.data.data.records;
          this.pagination.total = res.data.data.total;
          this.getInfoRate();
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

    //园校投保率
    getInfoRate() {
      let params = {
        type: 2,
        schoolId: this.schoolId,
      };
      this.$api.getInfoRate(params).then((res) => {
        this.tableLoading = false;
        if (res.data.code == 0) {
          this.infoRate = res.data.data;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

    //查看保单pdfpath
    getAttachmentPage() {
      let params = {
        type: 2,
      };
      this.$api.getAttachmentPage(params).then((res) => {
        this.tableLoading = false;
        if (res.data.code == 0) {
          this.attachmentFilelist = res.data.data.records;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

    //查看文件
    showFile() {
      if (this.attachmentFilelist.length > 0) {
        if (this.attachmentFilelist[0].pdfPath) {
          let url = "/mng/insurance/attachment/downloadFile";
          let data = {
            id: this.attachmentFilelist[0].attachmentId,
            type: 2,
            flag: 1,
          };
          let type = "get";
          this.onlinePreviewVisible = true;
          this.tableLoading = false;
          this.fileAttachment = {
            previewType: "pdf",
            url: this.attachmentFilelist[0].pdfPath,
            attachment_name: this.attachmentFilelist[0].attachment_name,
            attachment_type: this.attachmentFilelist[0].attachment_type,
          };
        } else {
          this.$message.error("暂不支持在线预览，请下载后查看");
          return;
        }
      } else {
        this.$message.warning("请上传后查看保单！");
      }
    },

    // 查询未投保信息
    getNotinsure() {
      let params = {
        schoolId: this.schoolId,
        type: "2",
        current: this.pagination.currentPage,
        size: this.pagination.size,
      };

      this.$api.getUninsuredInfo(params).then((res) => {
        this.tableLoading = false;
        if (res.data.code == 0) {
          this.tableNotinsure = res.data.data;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

    // 查询
    searchFun() {
      this.pagination.currentPage = 1;
      this.tableLoading = true;
      this.init();
    },

    // 重置
    resetFun() {
      this.form = {
        policyholder: "",
      };
      this.pagination.currentPage = 1;
      this.tableLoading = true;
      this.init();
    },

    /** 导出 */
    downLoadFile() {
      if (this.tableData.length > 0) {
        //下载文件
        let url = "/mng/insurance/info/exportList";

        let data = {
          schoolId: this.schoolId,
          level: "3",
          type: "2",
          parkId: "",
          policyholder: this.form.policyholder,
        };

        let type = "get";

        this.tableLoading = true;
        downloadFile({ url, data, type }).then(() => {
          this.tableLoading = false;
        });
      } else {
        this.$message.warning("暂无数据，不能导出！");
      }
    },

    /** 未投保导出 */
    downLoadFileNo() {
      if (
        this.tableNotinsure.beforeList == null &&
        this.tableNotinsure.afterList == null
      ) {
        this.$message.warning("暂无数据，不能导出！");
      } else {
        //下载文件
        let url = "/mng/insurance/info/exportUninsured";

        let data = {
          schoolId: this.schoolId,
          type: "2",
          parkId: "",
        };

        let type = "get";

        this.tableLoading = true;
        downloadFile({ url, data, type }).then(() => {
          this.tableLoading = false;
          this.disabledSync = false;
        });
      }
    },

    //未投保-已离职选中事件
    handleBeforeSelectionChange(val) {
      this.beforeSelectList = val;
    },

    //未投保-新入职选中事件
    handleAfterSelectionChange(val) {
      this.afterSelectList = val;
    },

    /** 同步 */
    onSync() {
      if (
        this.beforeSelectList.length == 0 &&
        this.afterSelectList.length == 0
      ) {
        this.$message.warning("请选择要同步的数据");
        return;
      }

      let afterSync = [];
      this.afterSelectList.map((item) => {
        afterSync.push(item.id);
      });

      let beforeSync = [];
      this.beforeSelectList.map((item) => {
        beforeSync.push(item.id);
      });

      if (!this.disabledSync) {
        let params = {
          afterIds: afterSync.join(","),
          beforeIds: beforeSync.join(","),
        };

        this.$api.getUpBathIdsInfo(params).then((res) => {
          this.tableLoading = false;
          if (res.data.code == 0) {
            console.log(res, "同步变更前变更后投保信息");
            this.getNotinsure();
          } else {
            this.$message.error(res.data.msg);
          }
        });
      }
    },

    //tabs切换
    handleTabClick(tab, event) {
      console.log(tab.index);
      this.tabClickIndex = tab.index;
      this.tableLoading = true;
      this.pagination.currentPage = 1;
      if (tab.index == 0) {
        this.init();
      } else {
        this.getNotinsure();
      }
    },

    closeOnlinePreviewDialog() {
      this.fileAttachment = {};
      this.onlinePreviewVisible = false;
    },

    /** 分页 */
    handleCurrentChangePage(val) {
      this.pagination.currentPage = val;
      this.tableLoading = true;
      this.init();
    },
    handleSizeChange(val) {
      this.pagination.size = val;
      this.tableLoading = true;
      this.init();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/css/select_list.scss";

.contain {
  // width: calc(100% - 40px);
  background-color: #ffffff;
  padding: 26px 30px 30px 30px;
  // box-sizing: border-box;

  .el-button.is-disabled,
  .el-button.is-disabled:focus,
  .el-button.is-disabled:hover {
    background-color: #c0c4cc !important;
  }
  // /deep/.el-tabs__header {
  //   margin: 0 0 30px;
  // }

  /deep/.el-tabs--border-card > .el-tabs__content {
    padding: 0;
  }

  /deep/.el-tabs--border-card {
    background: #fff;
    border: none;
    box-shadow: none;
  }

  /deep/.el-tabs__nav {
    border: 1px solid #003685;
    border-radius: 5px;
  }

  /deep/.el-tabs--border-card > .el-tabs__header .el-tabs__item {
    color: #003685;
  }

  /deep/.el-tabs--border-card > .el-tabs__header .el-tabs__item {
    // border: 1px solid #003685;
  }

  /deep/.el-tabs--border-card > .el-tabs__header .el-tabs__item:first-child {
    border-right-color: #003685;
    border-left-color: #003685;
    border-radius: 5px 0 0 5px;
    width: 120px;
    text-align: center;
  }
  /deep/.el-tabs--border-card > .el-tabs__header .el-tabs__item:last-child {
    border-radius: 0 5px 5px 0;
    color: red;
  }

  /deep/.el-tabs--border-card > .el-tabs__header {
    background-color: transparent;
    border-bottom: none;
    margin: 0;
  }

  /deep/.el-tabs__item {
    font-size: 16px;
  }
  /deep/.el-tabs--border-card
    > .el-tabs__header
    .el-tabs__item:not(.is-disabled):hover {
    color: #e7c69c;
  }

  /deep/.el-tabs__item:hover {
    color: #003685;
    cursor: pointer;
  }

  /deep/.el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
    border-right-color: transparent;
    border-left-color: #003685;
    border-top-color: #003685;
    // border-radius: 5px 0 0 5px;
    background-color: #003685;
    color: #fff;
  }

  /deep/.el-tabs__active-bar {
    background-color: #2a00b2;
  }

  .noinsure-title {
    color: #606266;
    text-align: center;
    line-height: 50px;
    border: 1px solid #ebeef5;
  }

  .form-btn {
    display: flex;
    margin: 20px 0;

    .sheet-btn {
      background: #2a00b2;
      box-shadow: 0px 3px 10px rgba(52, 85, 236, 0.4);
      opacity: 1;
      border-radius: 4px;
      font-size: 14px;
      color: #ffffff;
    }

    /deep/.el-button + .el-button {
      margin-left: 20px;
    }
  }

  .search-form {
    margin-top: 20px;
  }

  .manger-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
    padding-bottom: 5px;

    .footer-left-title {
      color: #606266;
    }
    .footer-left-num {
      color: red;
    }
  }
}
</style>
