var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"contain"},[_c('div',{staticClass:"form-header-btn"},[_c('div',{staticClass:"header-title"},[_vm._v("员工校方责任险")]),(_vm.tabClickIndex && _vm.tabClickIndex == 1)?_c('div',[(_vm.isUninsuredExport)?_c('el-button',{staticClass:"sheet-btn",attrs:{"type":"primary"},on:{"click":_vm.downLoadFileNo}},[_vm._v(" 导出 ")]):_vm._e(),(_vm.isUninsuredSync)?_c('el-button',{staticClass:"sheet-btn",attrs:{"disabled":_vm.disabledSync},on:{"click":_vm.onSync}},[_vm._v(" 同步 ")]):_vm._e()],1):_c('div',[(_vm.isView)?_c('el-button',{staticClass:"sheet-btn",attrs:{"type":"primary"},on:{"click":_vm.showFile}},[_vm._v(" 查看保单 ")]):_vm._e(),(_vm.isExport)?_c('el-button',{staticClass:"sheet-btn",attrs:{"type":"primary"},on:{"click":_vm.downLoadFile}},[_vm._v(" 导出 ")]):_vm._e()],1)]),_c('el-tabs',{attrs:{"type":"border-card"},on:{"tab-click":_vm.handleTabClick}},[_c('el-tab-pane',[_c('span',{attrs:{"slot":"label"},slot:"label"},[_vm._v("全部")]),_c('div',[_c('el-form',{staticClass:"search-form",attrs:{"model":_vm.form,"onsubmit":"return false","inline":true}},[_c('el-form-item',[_c('el-input',{staticClass:"select-condition",attrs:{"placeholder":"投保人","clearable":""},model:{value:(_vm.form.policyholder),callback:function ($$v) {_vm.$set(_vm.form, "policyholder", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.policyholder"}})],1),_c('el-form-item',[_c('el-button',{attrs:{"type":"primary","icon":"el-icon-search"},on:{"click":_vm.searchFun}},[_vm._v(" 查询 ")])],1),_c('el-form-item',[_c('el-button',{staticClass:"reset",attrs:{"icon":"el-icon-refresh-left"},on:{"click":_vm.resetFun}},[_vm._v(" 重置 ")])],1)],1),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.tableLoading),expression:"tableLoading"}],attrs:{"data":_vm.tableData,"header-cell-style":{
            background: '#F1F4F9',
            color: '#5E6D9B',
            fontWeight: '400',
            'text-align': 'center',
          }}},[_c('el-table-column',{attrs:{"type":"index","label":"序号","min-width":"80","align":"center"}}),_c('el-table-column',{attrs:{"prop":"insuranceType","label":"保险类型","min-width":"110","align":"center"}}),_c('el-table-column',{attrs:{"prop":"policyholder","label":"投保人","min-width":"110","align":"center"}}),_c('el-table-column',{attrs:{"prop":"identityNumber","label":"身份证号/护照号","min-width":"200","align":"center"}}),_c('el-table-column',{attrs:{"prop":"gender","label":"性别","min-width":"100","align":"center"}}),_c('el-table-column',{attrs:{"prop":"birthday","label":"生日","min-width":"110","align":"center"}})],1),_c('div',{staticClass:"manger-footer"},[_c('div',[_c('span',{staticClass:"footer-left-title"},[_vm._v("园校投保率：")]),_c('span',{class:_vm.infoRate < 100 ? 'footer-left-num' : 'footer-left-title'},[_vm._v(" "+_vm._s(_vm.infoRate || 0)+"% ")])]),_c('div',{staticClass:"footer-pagination"},[_c('el-pagination',{attrs:{"background":"","layout":"total,sizes, prev, pager, next, jumper","total":_vm.pagination.total,"page-size":_vm.pagination.size,"current-page":_vm.pagination.currentPage},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChangePage,"update:currentPage":function($event){return _vm.$set(_vm.pagination, "currentPage", $event)},"update:current-page":function($event){return _vm.$set(_vm.pagination, "currentPage", $event)}}})],1)])],1)]),_c('el-tab-pane',{attrs:{"label":"未投保预警"}},[_c('div',{staticStyle:{"padding-top":"1.875vw"}},[_c('div',[_c('el-row',[_c('el-col',{attrs:{"span":12}},[_c('div',{staticClass:"noinsure-title"},[_vm._v("变更前（已离职）")]),_c('div',{},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.tableLoading),expression:"tableLoading"}],attrs:{"data":_vm.tableNotinsure.beforeList,"border":"","header-cell-style":{
                    background: '#F1F4F9',
                    color: '#5E6D9B',
                    fontWeight: '400',
                    'text-align': 'center',
                  }},on:{"selection-change":_vm.handleBeforeSelectionChange}},[(!_vm.disabledSync)?_c('el-table-column',{attrs:{"type":"selection","width":"55","align":"center"}}):_vm._e(),_c('el-table-column',{attrs:{"type":"index","label":"序号","width":"60","align":"center"}}),_c('el-table-column',{attrs:{"prop":"policyholder","label":"姓名","align":"center"}}),_c('el-table-column',{attrs:{"prop":"parkName","label":"所属园校","align":"center"}}),_c('el-table-column',{attrs:{"prop":"identityNumber","label":"身份证号","width":"200","align":"center"}})],1)],1)]),_c('el-col',{attrs:{"span":12}},[_c('div',{staticClass:"noinsure-title"},[_vm._v("变更后（新入职未投保）")]),_c('div',[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.tableLoading),expression:"tableLoading"}],attrs:{"data":_vm.tableNotinsure.afterList,"border":"","header-cell-style":{
                    background: '#F1F4F9',
                    color: '#5E6D9B',
                    fontWeight: '400',
                    'text-align': 'center',
                  }},on:{"selection-change":_vm.handleAfterSelectionChange}},[(!_vm.disabledSync)?_c('el-table-column',{attrs:{"type":"selection","width":"55","align":"center"}}):_vm._e(),_c('el-table-column',{attrs:{"type":"index","label":"序号","width":"60","align":"center"}}),_c('el-table-column',{attrs:{"prop":"policyholder","label":"姓名","align":"center"}}),_c('el-table-column',{attrs:{"prop":"parkName","label":"所属园校","align":"center"}}),_c('el-table-column',{attrs:{"prop":"identityNumber","label":"身份证号","width":"200","align":"center"}})],1)],1)])],1)],1)])])],1),(_vm.attachmentFilelist.length > 0)?_c('onlinePreview',{attrs:{"onlinePreviewVisible":_vm.onlinePreviewVisible,"type":_vm.fileAttachment.previewType,"url":_vm.fileAttachment.url,"title":_vm.attachmentFilelist[0].attachmentName || ''},on:{"closeOnlinePreviewDialog":_vm.closeOnlinePreviewDialog}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }